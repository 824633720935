export const jobData = [
    {
        type: '기획/전략',
        work: [
            '경영기획',
            '광고기획',
            '교육기획',
            '마케팅기획',
            '사업기획',
            '브랜드기획',
            '상품기획',
            '서비스기획',
            '앱기획',
            '웹기획',
            '인사관리',
            '경영분석',
        ],
    },
    {
        type: '마케팅/홍보',
        work: [
            '광고마케팅',
            '글로벌마케팅',
            '디지털마케팅',
            '마케팅전략',
            '바이럴마케팅',
            '브랜드마케팅',
            '퍼포먼스 마케팅',
            '스포츠마케팅',
            '그로스해킹',
            '언론홍보',
        ],
    },
    {
        type: 'IT 개발',
        work: [
            '게임개발',
            '기술지원',
            '데이터분석가',
            '데이터엔지니어',
            '백엔드/서버 개발',
            '보안컨설팅',
            '앱개발',
            '웹개발',
            '유지보수',
            '정보보안',
            '검색엔진',
            '데이터라벨링',
            '데이터마이닝',
            '데이터시각화',
            '딥러닝',
            '메타버스',
            '블록체인',
        ],
    },
    {
        type: '디자인',
        work: [
            '가구디자인',
            '건축디자인',
            '게임디자인',
            '공간디자인',
            '공공디자인',
            '공예디자인',
            '광고디자인',
            '그래픽디자인',
            '디지털디자인',
            '로고디자인',
            '무대디자인',
            '브랜드디자인',
        ],
    },
    {
        type: '미디어/문화',
        work: [
            '에디터',
            '공연예술',
            '뉴미디어',
            '드라마',
            '라이브커머스',
            '레크레이션',
            '레저',
            '만화/웹툰',
            '무대제작',
            '문화재',
            '뮤지컬',
            '미디어플래너',
        ],
    },
];
